import axios from 'axios';
import { configure } from 'axios-hooks';
import qs from 'qs';

configure({
  axios,
  defaultOptions: {
    useCache: false,
  },
});

if (window.location.hostname === 'localhost') {
  axios.defaults.withCredentials = true;
}

const TOKEN_STORAGE_KEY = 'lea.auth0.token';

axios.interceptors.request.use((request) => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);

  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  request.headers['neon-language'] = localStorage.getItem('language');

  return request;
});

export function getAccessToken() {
  return localStorage.getItem(TOKEN_STORAGE_KEY);
}

export function clearAccessToken() {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
}

export function getAccessTokenQueryString(query = {}) {
  let token = getAccessToken();

  if (token) {
    query.access_token = token;
  }

  return qs.stringify(query);
}
