import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import useAxios from 'axios-hooks';
import './DocumentDebugInfoModal.scss';

function DocumentDebugInfoModal({ documentType, documentId, onDismiss }) {
  const [copied, setCopied] = useState(false);
  const [{ data, loading }] = useAxios(
    `${process.env.REACT_APP_API_ENDPOINT}/debug/${documentType}-documents/${documentId}`
  );
  let content;

  if (loading) {
    content = 'Loading...';
  } else {
    const list = Object.entries(data).map(([key, value]) => (
      <div key={key}>
        <h2>{key}</h2>
        <Input type="textarea" rows="10" className="mb-4" value={JSON.stringify(value, null, 4)} readOnly />
      </div>
    ));

    content = (
      <>
        <div className="text-right">
          <Button
            color="secondary"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(JSON.stringify(data, null, 4));
                setCopied(true);
              } catch {
                // ignore
              }
            }}
          >
            {copied ? 'Copied!' : 'Copy all'}
          </Button>
        </div>
        {list}
      </>
    );
  }

  return (
    <Modal isOpen size="lg" className="DocumentDebugInfoModal" toggle={() => onDismiss()}>
      <ModalHeader toggle={() => onDismiss()}>Debug info</ModalHeader>
      <ModalBody>{content}</ModalBody>
    </Modal>
  );
}

DocumentDebugInfoModal.propTypes = {
  documentType: PropTypes.oneOf(['accounting', 'banking']).isRequired,
  documentId: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default DocumentDebugInfoModal;
