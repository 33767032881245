import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'containers/withRouter';
import { Alert, Badge, ListGroup } from 'reactstrap';
import moment from 'moment';
import BankingDocumentEdit from 'components/bankingDocumentEdit/BankingDocumentEdit';
import SidePanelToggle from 'components/sidePanelToggle/SidePanelToggle';
import translate from 'containers/translate';
import { providesBankingDocuments, consumesBankingDocuments } from 'contexts/BankingDocumentsContext';
import { consumesAccounts, providesAccounts } from 'contexts/AccountsContext';
import { consumesPeriods } from 'contexts/PeriodsContext';
import RemoteDataPropType from 'lib/prop-types/RemoteDataPropType';
import './BankingDocumentsStep.scss';

class BankingDocumentsStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closed: false,
      showDebugModal: false,
    };

    this.navigateToBankingDocument = this.navigateToBankingDocument.bind(this);
    this.handleListToggle = this.handleListToggle.bind(this);
    this.sortBankingDocuments = this.sortBankingDocuments.bind(this);
  }

  componentDidUpdate() {
    if (!this.props.match.params.documentId) {
      const nonVirtualDocuments = this.getNonVirtualBankingDocuments();

      if (nonVirtualDocuments.length > 0) {
        this.navigateToBankingDocument(nonVirtualDocuments[0]);
      }
    }
  }

  render() {
    if (this.props.remoteAccounts.isLoading) {
      return <div className="loader" />;
    }

    return (
      <div className="BankingDocumentsStep content-with-sidepanel">
        {this.renderList()}
        {this.renderDocument()}
      </div>
    );
  }

  renderList() {
    const { t } = this.props;

    if (this.state.closed) {
      const wrapperClass = classnames({ 'is-closed': this.state.closed });

      return (
        <div className={wrapperClass}>
          <SidePanelToggle closed={this.state.closed} onToggle={this.handleListToggle} />
        </div>
      );
    }

    const nonVirtualDocuments = this.getNonVirtualBankingDocuments().sort(this.sortBankingDocuments);
    let currentAccountId = null;

    return (
      <div>
        <ListGroup>
          {nonVirtualDocuments.map((bankingDocument) => {
            const active = bankingDocument.id === parseInt(this.props.match.params.documentId, 10);
            const buttonClass = classnames('list-group-item list-group-item-action', { active });
            let editedBadge;
            let accountHeader;

            if (bankingDocument.edited) {
              editedBadge = (
                <Badge color="success" pill className="float-right">
                  {t('edited')}
                </Badge>
              );
            }

            if (currentAccountId !== bankingDocument.account.id) {
              currentAccountId = bankingDocument.account.id;
              accountHeader = (
                <div className="list-group-item font-weight-bold">
                  <div>{this.getNameFromDocument(bankingDocument)}</div>
                  <div>{bankingDocument.account.account}</div>
                </div>
              );
            }

            return (
              <React.Fragment key={bankingDocument.id}>
                {accountHeader}
                <button
                  type="button"
                  className={buttonClass}
                  onClick={() => this.navigateToBankingDocument(bankingDocument)}
                >
                  {editedBadge}
                  <div>{this.renderDocumentDateRange(bankingDocument)}</div>
                  <div>{bankingDocument.bank && bankingDocument.bank.name}</div>
                </button>
              </React.Fragment>
            );
          })}
        </ListGroup>
        <SidePanelToggle closed={this.state.closed} onToggle={this.handleListToggle} />
      </div>
    );
  }

  renderDocumentDateRange(bankingDocument) {
    const from = moment(bankingDocument.dateFrom ?? new Date()).format('DD.MM.');
    const to = moment(bankingDocument.dateTo ?? new Date()).format('DD.MM.');

    return `${from} - ${to}`;
  }

  getNameFromDocument(bankingDocument) {
    if (!bankingDocument.account.bank) {
      return bankingDocument.account.name;
    } else {
      return `${bankingDocument.account.bank.name} - ${bankingDocument.account.name}`;
    }
  }

  getNonVirtualBankingDocuments() {
    return this.props.bankingDocuments.filter(({ isVirtualDocument }) => !isVirtualDocument);
  }

  handleListToggle() {
    this.setState((prevState) => {
      return {
        closed: !prevState.closed,
      };
    });
  }

  sortBankingDocuments(a, b) {
    const accountA = parseInt(a.account.account, 10);
    const accountB = parseInt(b.account.account, 10);

    const accountComparision = accountA - accountB;
    if (accountComparision !== 0) {
      return accountComparision;
    }

    return moment.utc(a.dateFrom).diff(moment.utc(b.dateFrom));
  }

  navigateToBankingDocument(bankingDocument) {
    this.props.history.pushDossier(`/banking-documents/${bankingDocument.id}${this.props.location.search}`);
  }

  renderDocument() {
    const { t } = this.props;

    if (this.props.bankingDocuments.length === 0) {
      return <Alert color="info">{t('err_no_documents_found')}</Alert>;
    }

    if (this.props.match.params.documentId) {
      return (
        <BankingDocumentEdit
          bankingDocuments={this.props.bankingDocuments}
          readOnly={this.props.remotePeriods.isReadOnlyPeriod}
        />
      );
    }
  }
}

BankingDocumentsStep.propTypes = {
  t: PropTypes.func.isRequired,
  remoteAccounts: RemoteDataPropType,
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  bankingDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  remoteBankingDocuments: RemoteDataPropType,
  remotePeriods: RemoteDataPropType,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  providesAccounts(
    providesBankingDocuments(
      consumesAccounts(consumesBankingDocuments(consumesPeriods(translate(BankingDocumentsStep))))
    )
  )
);
